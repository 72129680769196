<h1 mat-dialog-title>CREATE APPOINTMENT </h1>
<div mat-dialog-content>
  <form [formGroup]="appointments_form">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group row mb-2">
          <label class="col-lg-3 col-form-label text-end">Channeling Center</label>
          <div class="col-lg-9">
            <select placeholder="Select Channeling Center" formControlName="channeling_center" class="form-select"
              (change)="getCenterName($event)">
              <option value="{{channeling_center._doc._id}}" *ngFor="let channeling_center of channeling_centers">
                {{channeling_center._doc.channeling_center_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group row mb-2">
          <label class="col-lg-3 col-form-label text-end">Slot</label>
          <div class="col-lg-9">
            <select placeholder="Select Channeling Center" formControlName="slot" class="form-select">
              <option value="{{slot._doc._id}}##{{slot._doc.date}}" *ngFor="let slot of slots">
                {{slot._doc.start_time}}-{{slot._doc.end_time}} ({{slot._doc.date}})
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group row mb-2">
          <label class="col-lg-3 col-form-label text-end">Patient </label>
          <div class="col-lg-9">
            <select placeholder="Select Patient" formControlName="patient" (change)="getPatientName($event)"
              class="form-select">
              <option value="{{patient._doc._id}}" *ngFor="let patient of patients_list">
                {{ patient._doc.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button class="btn btn-de-primary btn-sm text-light px-4 mt-3 mb-0" (click)="createAppointment()">Save</button>
  <button class="btn btn-de-danger btn-sm text-light px-4 mt-3 mb-0" (click)="cancel()">Cancel</button>
</div>
