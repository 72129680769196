import {Injectable} from '@angular/core';
import {ToastService} from '../misc/toast.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PatientsService {

  constructor(
    private http: HttpClient,
    private toast: ToastService,
  ) {
  }

  create(request_body) {
    return new Promise(resolve => {
      this.http.post(`${environment.api_url}/patients/create`, request_body).pipe(
        this.toast.observe('Record Saving..', 'Successfully Saved', 'Something Went Wrong')
      ).subscribe(res => {
        resolve(res);
      });
    });
  }

  updateFromPatientForm(request_body){
    return new Promise(resolve => {
      this.http.put(`${environment.api_url}/patients/update`, request_body).pipe(
      ).subscribe(res => {
        // @ts-ignore
        resolve(res);
      });
    });
  }
  update(request_body) {
    return new Promise(resolve => {
      this.http.put(`${environment.api_url}/patients/update`, request_body).pipe(
        this.toast.observe('Record Updating..', 'Successfully Updated', 'Something Went Wrong')
      ).subscribe(res => {
        // @ts-ignore
        resolve(res);
      });
    });
  }

  updateWithoutToast(request_body) {
    return new Promise(resolve => {
      this.http.put(`${environment.api_url}/patients/update`, request_body).pipe(
      ).subscribe(res => {
        // @ts-ignore
        resolve(res);
      });
    });
  }

  list() {
    return new Promise(resolve => {
      this.http.get(`${environment.api_url}/patients`).subscribe(res => {
        resolve(res);
      });
    });
  }

  delete(request_body) {
    return new Promise(resolve => {
      this.http.delete(`${environment.api_url}/patients/delete/${request_body}`).pipe(
        this.toast.observe('Record Deleting..', 'Successfully Deleted', 'Something Went Wrong')
      ).subscribe(res => {
        // @ts-ignore
        resolve(res.data);
      });
    });
  }

  show(request_body) { 
    return new Promise(resolve => {
      this.http.get(`${environment.api_url}/patients/show/${request_body}`).subscribe(res => {
        resolve(res);
      });
    });
  }

}
