import {Component, OnInit} from '@angular/core';
import {formatDate} from '@angular/common';
import {SidebarService} from "../../../core/services/misc/sidebar.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  display_logo = true;

  current_date = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US');
  bookings: any = [];
  current_patient: any;
  selected_date: any;

  constructor(private sidebar: SidebarService,
              private router: Router,) {
    SidebarComponent.resizeEvent();
    SidebarComponent.menuToggleOnResize();
  }

  ngOnInit(): void {
    this.fetchBookings(true);
    this.getBookings();
  }

  getBookings() {
    setInterval(() => {
      this.fetchBookings();
    }, 5000);
  }

  fetchBookings(store_slot = false){
    if (this.current_date != this.selected_date) {
      this.selected_date = null;
      this.current_patient = null;
    }
    this.sidebar.getBookings(this.current_date).then(res => {
      this.bookings = res;
      if (store_slot) {
        this.storeData(res[0]);
      }
    })
  }

  loadDashboard(patient_id: any, visit_doc_id, visit_type, item, index) {
    this.current_patient = index;
    this.selected_date = this.current_date;
    this.storeData(item);
    if (visit_type == 'visit') {
      this.router.navigate(['dashboard', patient_id]);
    } else {
      this.edit(visit_doc_id);
    }
  }

  private static menuToggleOnResize() {
    let window_width = window.innerWidth;
    if (window_width < 1025) {
      document.getElementsByTagName('body')[0].classList.add('enlarge-menu', 'enlarge-menu-all');
    } else if (window_width < 1340) {
      document.getElementsByTagName('body')[0].classList.remove('enlarge-menu-all');
      document.getElementsByTagName('body')[0].classList.add('enlarge-menu');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('enlarge-menu', 'enlarge-menu-all');
    }
  }

  private static resizeEvent() {
    window.addEventListener('resize', function () {
      let window_width = window.innerWidth;
      if (window_width < 1025) {
        document.getElementsByTagName('body')[0].classList.add('enlarge-menu', 'enlarge-menu-all');
      } else if (window_width < 1340) {
        document.getElementsByTagName('body')[0].classList.remove('enlarge-menu-all');
        document.getElementsByTagName('body')[0].classList.add('enlarge-menu');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('enlarge-menu', 'enlarge-menu-all');
      }
    });
  }
  edit(document_id: string) {
    this.router.navigate(['appointments/edit', document_id]);
  }

  storeData(item: any) {
    localStorage.setItem('slot', JSON.stringify(item));
  }
}
