import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { OffCanvasComponent } from './off-canvas/off-canvas.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { Select2Module } from 'ng-select2-component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    OffCanvasComponent,
    SidebarComponent
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    OffCanvasComponent,
    SidebarComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        Select2Module,
        NgbModule
    ]
})
export class ComponentsModule { }
