<div class="left-sidebar">
  <div class="brand">
    <a>
      <span>
<!--        <img src="assets/images/logomed.png" id="logo-img" class="logo-lg  medapp-logo-admin" alt="main-logo">-->
        <img src="assets/images/appfav-01.png " id="logo-img" class="logo-lg logo-img-sm medapp-logo-admin" alt="main-logo">
<!--        <img src="assets/images/appfav-01.png" style="display: none" id="logo-img-sm"-->
<!--             class="logo-lg logo-img-sm medapp-logo-admin" alt="main-logo">-->
      </span>
    </a>
  </div>
  <div class="menu-content h-100" data-simplebar>
    <div class="date-selector">
      <input type="date" class="form-control" [(ngModel)]="current_date" (ngModelChange)="getBookings()">
    </div>
    <div class="menu-body navbar-vertical tab-content menuitem-active">
      <div class="collapse navbar-collapse show" id="sidebarCollapse">
        <ul class="navbar-nav">
          <li class="menu-label mt-0"><span>Channeling Centers</span></li>
          <li class="nav-item menuitem-active" *ngFor="let item of bookings; let i = index">
            <a class="nav-link" href="#{{item.hospital.replaceAll(' ','')}}" data-bs-toggle="collapse" role="button"
               aria-expanded="true" aria-controls="" (click)="storeData(item)">
              <span>{{item.hospital}} ({{item.start_time}} - {{item.end_time}})</span>
            </a>
            <div class="collapse show" id="{{item.hospital.replaceAll(' ','')}}">
              <ul class="nav flex-column">
                <li class="nav-item menuitem-active" *ngFor="let patient of item.patients ;let idx = index">
                  <div class="card" [class.active-item]="(i+''+idx) == current_patient" (click)="loadDashboard(patient.patient_id, patient._id, patient.type, item, (i+''+idx))">
                    <div class="row g-0">
                      <div class="col-md-2 align-self-center">
                        <button type="button" class="btn btn-soft-primary btn-icon-circle btn-icon-circle-sm me-2 position-relative">
                          {{patient.token}}
                        </button>
                      </div>
                      <div class="col-md-10">
                        <div class="card-body">
                          <h5 class="card-title" *ngIf="patient.type == 'visit' else booking_name">{{patient.patient_name}}</h5>
                          <ng-template #booking_name>
                            <h5 class="card-title">{{patient.booking_name}}</h5>
                          </ng-template>
                          <p class="card-text mb-0" *ngIf="patient.type == 'visit' else make"><small class="text-muted">{{patient.visit_id}}</small></p>
                          <ng-template #make>
                            <a class="card-text mb-0"><small class="text-muted">Make Appointment</small></a>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
