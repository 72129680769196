import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { Select2Module } from 'ng-select2-component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import { AppComponent } from './app.component';
import {AdminLayoutComponent} from './resources/layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './resources/layouts/auth-layout/auth-layout.component';
import {ComponentsModule} from './resources/components/components.module';
import { HotToastModule } from '@ngneat/hot-toast';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { GettingStartComponent } from './pages/getting-start/getting-start.component';
import {MatInputModule} from '@angular/material/input';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { QuickBrandComponent } from './pages/dialogs/quick-brand/quick-brand.component';
import { TagInputModule } from 'ngx-chips';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DiabetesComponent } from './forms/diabetes/diabetes.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { BglComponent } from './pages/dialogs/bgl/bgl.component';
import { AddMicrovascularScreeningComponent } from './pages/dialogs/add-microvascular-screening/add-microvascular-screening.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import { DentistComponent } from './forms/dentist/dentist.component';
import { MicrovascularScreeningComponent } from './pages/dialogs/microvascular-screening/microvascular-screening.component';
import { DrSampathComponent } from './forms/dr-sampath/dr-sampath.component';


@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatInputModule,
        FormsModule,
        HttpClientModule,
        CoreModule,
        AppRoutingModule,
        ComponentsModule,
        ReactiveFormsModule,
        Select2Module,
        AutocompleteLibModule,
        TagInputModule,
        NgbModule,
        HotToastModule.forRoot(),
        MatExpansionModule,
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
