<app-sidebar></app-sidebar>
<app-header></app-header>
<div class="page-wrapper">
  <!-- Page Content-->
  <div class="page-content-tab">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="page-title-box">
<!--            <p>{{window.location}}</p>-->
          </div><!--end page-title-box-->
        </div>
        <router-outlet></router-outlet>
      </div>
      <app-off-canvas></app-off-canvas>
    </div>
    <app-footer></app-footer>
  </div>
</div>
