import {Injectable} from '@angular/core';
import {ElectronService} from '../electron/electron.service';
import {ToastService} from '../misc/toast.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelingCenterService {

  constructor(
    private toast: ToastService,
    private http: HttpClient
  ) {
  }

  create(request_body) {
    return new Promise(resolve => {
      this.http.post(`${environment.api_url}/hospital/create`, request_body).pipe(
        this.toast.observe('Record Saving..', 'Successfully Saved', 'Something Went Wrong')
      ).subscribe(res => {
        resolve(res);
      });
    });
  }

  update(request_body) {
    return new Promise(resolve => {
      this.http.put(`${environment.api_url}/hospital/update`, request_body).pipe(
        this.toast.observe('Record Updating..', 'Successfully Updated', 'Something Went Wrong')
      ).subscribe(res => {
        resolve(res);
      });
    });
  }

  list() {
    return new Promise(resolve => {
      this.http.get(`${environment.api_url}/hospital`).subscribe(res => {
        resolve(res);
      });
    });
  }

  delete(request_body) {
    return new Promise(resolve => {
      this.http.delete(`${environment.api_url}/hospital/delete/${request_body}`).pipe(
        this.toast.observe('Record Deleting..', 'Successfully Deleted', 'Something Went Wrong')
      ).subscribe(res => {
        // @ts-ignore
        resolve(res.data);
      });
    });
  }

  show(request_body) { //method changed
    return new Promise(resolve => {
      this.http.get(`${environment.api_url}/hospital/show/${request_body}`).subscribe(res => {
        resolve(res);
      });
    });
  }

}
